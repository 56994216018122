
import { defineComponent } from "vue";
import { Form } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import SearchBar from "@/components/search-bars/SearchBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin";
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
    mixins: [apiEndpoint],
    name: "pending enrollment list Print",
    components: {
        Form,
        Datatable,
        SearchBar,
        Swal,
        ElNotification,
    },
    data() {
        return {
            api_url: "",
            load: false,
            batchInfo: [],
            tableHeader: [
                {
                    name: "SL",
                    key: "sl",
                    sortable: true,
                },
                {
                    name: "Tranche",
                    key: "tranche",
                    sortable: true,
                },
                {
                    name: "Training Partner",
                    key: "training_partner",
                    sortable: true,
                },
                {
                    name: "Institute",
                    key: "ti",
                    sortable: true,
                },
                {
                    name: "Course",
                    key: "course",
                    sortable: true,
                },
                {
                    name: "Batch No",
                    key: "batch",
                    width: '150px',
                    sortable: true,
                },
            ],
            componentKey: 0,
            showDetails: false,
            showCourseNotice: false,
            showtrainingProviderNotice: false,
        };
    },
    async created() {
        this.api_url = this.VUE_APP_API_URL;
        await this.getPendingAssessment();

    },
    methods: {
        async printPdf() {
            window.open('/pending-enrollment-list_print')
        },
       
        async getPendingAssessment() {
            this.load = true;
            let entity_id = "";
            let institute_id = "";
            if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
                entity_id = VueCookieNext.getCookie("_entity_id");
            }
            if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
                institute_id = VueCookieNext.getCookie("_institute_info_id");
            }
            ApiService.get(
                "batch/pending_enrollment?entity_id=" +
                entity_id +
                "&institute_info_id=" +
                institute_id
            )
                .then((response) => {
                    this.load = false;
                    this.batchInfo = response.data.data;
                    this.showDetails = true;
                })
                .catch((response) => {
                    this.load = false;
                    console.log(response);
                });
        },
    },
    setup() {
        const AssociationSchema = [];
        return {
            AssociationSchema,
        };
    },
});
